import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "modern-normalize/modern-normalize.css";
import "./index.css";
import {App} from "./components/App";
import reportWebVitals from "./reportWebVitals";
import {createStore} from "./store";

const container = document.getElementById("root")!;
Modal.setAppElement(container);

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  container,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
