import {memo, useCallback} from "react";
import {updateProduction, updateStockpile} from "../actions";
import {useDispatch, useSelector} from "../hooks";
import {getProduction, getStockpile} from "../selectors";
import {ResourceType, ResourceMap} from "../types";
import {ResourceView as ResourceViewDefaut} from "./ResourceView";

const ResourceView = memo(ResourceViewDefaut);

const RESOURCE_EMOJIS: ResourceMap<string> = {
  money: "💰",
  steel: "🔨",
  titanium: "⭐",
  plant: "🌱",
  energy: "⚡",
  heat: "🔥",
};

const RESOURCE_NAMES: ResourceMap<string> = {
  money: "Megacredits",
  steel: "Steel",
  titanium: "Titanium",
  plant: "Plants",
  energy: "Energy",
  heat: "Heat",
};

export function Resource({resource}: {resource: ResourceType}) {
  const dispatch = useDispatch();
  const stockpile = useSelector(getStockpile);
  const production = useSelector(getProduction);

  const onStockpileChange = useCallback(
    (value: number) => {
      dispatch(updateStockpile(resource, value));
    },
    [dispatch, resource],
  );

  const onProductionChange = useCallback(
    (value: number) => {
      dispatch(updateProduction(resource, value));
    },
    [dispatch, resource],
  );

  return (
    <ResourceView
      name={RESOURCE_NAMES[resource]}
      emoji={RESOURCE_EMOJIS[resource]}
      stockpile={stockpile[resource]}
      production={production[resource]}
      onStockpileChange={onStockpileChange}
      onProductionChange={onProductionChange}
      allowNegativeProduction={resource === "money"}
    />
  );
}
