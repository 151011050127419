import {advanceGeneration, undo, redo, reset, updateTr} from "../actions";
import {useSelector, useDispatch} from "../hooks";
import {
  getGeneration,
  getTr,
  isRedoAvailable,
  isUndoAvailable,
} from "../selectors";
import {RESOURCE_TYPES} from "../types";
import {Resource} from "./Resource";
import {StatePersistence} from "./StatePersistence";
import styles from "./AppView.module.css";
import {ResourceView} from "./ResourceView";

export function AppView() {
  // state
  const canUndo = useSelector(isUndoAvailable);
  const canRedo = useSelector(isRedoAvailable);
  const generation = useSelector(getGeneration);
  const tr = useSelector(getTr);

  // actions
  const dispatch = useDispatch();

  function confirmReset() {
    const confirmed = window.confirm("Are you sure?");
    if (confirmed) {
      dispatch(reset());
    }
  }

  function onTrChange(value: number) {
    dispatch(updateTr(value));
  }

  return (
    <div className={styles.container}>
      <StatePersistence />
      <div className={styles.resources}>
        {RESOURCE_TYPES.map((type) => (
          <Resource key={type} resource={type} />
        ))}
        <ResourceView
          name="TR"
          emoji="🌎"
          stockpile={tr}
          onStockpileChange={onTrChange}
        />
      </div>
      <div className={styles.infoPanel}>
        <div>Generation: {generation}</div>
        <div>
          <button onClick={() => dispatch(advanceGeneration())}>
            Next generation
          </button>
        </div>
        <div className={styles.toolbar}>
          <button onClick={() => dispatch(undo())} disabled={!canUndo}>
            Undo
          </button>
          <button onClick={() => dispatch(redo())} disabled={!canRedo}>
            Redo
          </button>
          <button onClick={confirmReset}>Reset</button>
        </div>
      </div>
    </div>
  );
}
