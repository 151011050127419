import {NumberTicker} from "./NumberTicker";
import styles from "./ResourceView.module.css";

export function ResourceView({
  name,
  emoji,
  stockpile,
  production,
  onStockpileChange,
  onProductionChange,
  allowNegativeProduction = false,
}: {
  name: string;
  emoji: string;
  stockpile: number;
  production?: number;
  onStockpileChange: (value: number) => void;
  onProductionChange?: (value: number) => void;
  allowNegativeProduction?: boolean;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.name}>
          {emoji} {name} {emoji}
        </div>
        <div className={styles.counterSection}>
          <NumberTicker
            value={stockpile}
            onChange={onStockpileChange}
            rem={1.5}
            label={name}
          />
        </div>
        {onProductionChange && typeof production === "number" && (
          <div
            className={`${styles.counterSection} ${styles.productionSection}`}
          >
            <NumberTicker
              value={production}
              onChange={onProductionChange}
              rem={1}
              suffix="/round"
              allowNegative={allowNegativeProduction}
              showSign
              label={`${name} production`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
