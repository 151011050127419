import {Suspense, StrictMode} from "react";
import {Provider} from "react-redux";
import {AppView} from "./AppView";
import {Store} from "../store";

export function App({store}: {store: Store}) {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <AppView />
        </Suspense>
      </Provider>
    </StrictMode>
  );
}
