import {
  AdvanceGenerationAction,
  RedoAction,
  ResetAction,
  UndoAction,
  UpdateProductionAction,
  UpdateStockpileAction,
  UpdateTrAction,
} from "./reducer";
import {ResourceType} from "./types";

export function advanceGeneration(): AdvanceGenerationAction {
  return {type: "ADVANCE_GENERATION"};
}

export function undo(): UndoAction {
  return {type: "UNDO"};
}

export function redo(): RedoAction {
  return {type: "REDO"};
}

export function reset(): ResetAction {
  return {type: "RESET"};
}

export function updateStockpile(
  resource: ResourceType,
  newValue: number,
): UpdateStockpileAction {
  return {type: "UPDATE_STOCKPILE", resource, newValue};
}

export function updateProduction(
  resource: ResourceType,
  newValue: number,
): UpdateProductionAction {
  return {type: "UPDATE_PRODUCTION", resource, newValue};
}

export function updateTr(newValue: number): UpdateTrAction {
  return {type: "UPDATE_TR", newValue};
}
