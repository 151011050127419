import {
  useDispatch as useDispatchDefault,
  useSelector as useSelectorDefault,
} from "react-redux";
import {Action, ReducerState} from "./reducer";

export const useDispatch: () => (action: Action) => void = useDispatchDefault;

export const useSelector: <T>(selector: (state: ReducerState) => T) => T =
  useSelectorDefault;
