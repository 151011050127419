import {ReducerState} from "./reducer";

export function getStockpile(state: ReducerState) {
  return state.stockpile;
}

export function getProduction(state: ReducerState) {
  return state.production;
}

export function getGeneration(state: ReducerState) {
  return state.generation;
}

export function getUndoStack(state: ReducerState) {
  return state.undoStack;
}

export function getRedoStack(state: ReducerState) {
  return state.redoStack;
}

export function isUndoAvailable(state: ReducerState) {
  return getUndoStack(state).length > 0;
}

export function isRedoAvailable(state: ReducerState) {
  return getRedoStack(state).length > 0;
}

export function getTr(state: ReducerState) {
  return state.tr;
}
