import {useEffect, useRef} from "react";
import {useSelector} from "../hooks";
import {save} from "../persistence";
import {ReducerState} from "../reducer";
import {persistenceKey} from "../store";

export function StatePersistence() {
  const stateRef = useRef<ReducerState | null>(null);
  const state = useSelector(identity);

  useEffect(() => {
    if (stateRef.current) {
      save(persistenceKey, state);
    }
    stateRef.current = state;
  }, [state]);

  return null;
}

function identity<T>(value: T): T {
  return value;
}
