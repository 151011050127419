import {createStore as createReduxStore} from "redux";
import {createKey, load} from "./persistence";
import {reducer, reducerStateSchema} from "./reducer";

export const persistenceKey = createKey("state", reducerStateSchema);

export function createStore() {
  const initialState = load(persistenceKey) ?? undefined;
  return createReduxStore(reducer, initialState);
}

export type Store = ReturnType<typeof createStore>;
