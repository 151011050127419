import * as t from "runtypes";

// resource enum

export const resourceTypeSchema = t.Union(
  t.Literal("money"),
  t.Literal("steel"),
  t.Literal("titanium"),
  t.Literal("plant"),
  t.Literal("energy"),
  t.Literal("heat"),
);

export const RESOURCE_TYPES = resourceTypeSchema.alternatives.map(
  (alt) => alt.value,
);
export type ResourceType = typeof RESOURCE_TYPES[number];

// resource map

export type ResourceMap<T> = {[key in ResourceType]: T};

export function resourceMapSchema<T>(
  valueSchema: t.Runtype<T>,
): t.Runtype<ResourceMap<T>> {
  return t.Dictionary(valueSchema, resourceTypeSchema);
}

export const resourceCounterSchema = resourceMapSchema(t.Number);
export type ResourceCounter = t.Static<typeof resourceCounterSchema>;

export const resourceAmountsSchema = t.Optional(resourceCounterSchema);
export type ResourceAmounts = Partial<ResourceCounter>;

// builders

export function buildResourceMap<T>(value: T): ResourceMap<T> {
  const map: Partial<ResourceMap<T>> = {};
  for (const resource of RESOURCE_TYPES) {
    map[resource] = value;
  }
  return map as ResourceMap<T>;
}

export function addProduction(
  stockpile: ResourceAmounts,
  production: ResourceAmounts,
): ResourceCounter {
  const sum = buildResourceMap(0);
  for (const resource of RESOURCE_TYPES) {
    const result = (stockpile[resource] ?? 0) + (production[resource] ?? 0);
    sum[resource] = Math.max(result, 0);
  }
  return sum;
}
