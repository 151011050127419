import {encode, decode} from "base2048";
import {deflate, inflate} from "pako";
import {Runtype} from "runtypes";

export type Key<T> = {
  id: string;
  schema: Runtype<T>;
};

export function createKey<T>(id: string, schema: Runtype<T>): Key<T> {
  return {id, schema};
}

export function save<T>(key: Key<T>, value: T): void {
  const json = JSON.stringify(value);
  const deflated = deflate(json);
  const encoded = encode(deflated);
  localStorage.setItem(key.id, encoded);
}

export function load<T>(key: Key<T>): T | null {
  const encoded = localStorage.getItem(key.id);
  if (typeof encoded !== "string") {
    return null;
  }
  try {
    const decoded = decode(encoded);
    const inflated = inflate(decoded);
    const json = new TextDecoder().decode(inflated);
    const parsed: unknown = JSON.parse(json);
    return key.schema.check(parsed);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function reset<T>(key: Key<T>): void {
  localStorage.removeItem(key.id);
}
