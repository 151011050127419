import {FormEvent, useState} from "react";
import Modal from "react-modal";
import styles from "./NumberTicker.module.css";

export function NumberTicker({
  rem = 1,
  value,
  onChange,
  allowNegative = false,
  showSign = false,
  suffix,
  label,
}: {
  rem?: number;
  value: number;
  onChange: (newValue: number) => void;
  allowNegative?: boolean;
  showSign?: boolean;
  suffix?: string;
  label: string;
}) {
  const [inputModalVisible, setInputModalVisible] = useState(false);
  const [input, setInput] = useState("");

  const trimmed = input.trim();
  let parsedInput = /^(-|\+)?\d+$/.test(trimmed)
    ? Number.parseInt(trimmed)
    : null;

  if (!allowNegative && typeof parsedInput === "number" && parsedInput < 0) {
    parsedInput = null;
  }

  const invalidInput = Boolean(trimmed && typeof parsedInput !== "number");

  function showInputModal() {
    setInputModalVisible(true);
  }

  function hideInputModal() {
    setInputModalVisible(false);
    setInput("");
  }

  const buttonStyle = {width: `${rem * 1.25}rem`, textAlign: "center" as const};

  const canDecrease = value > 0 || allowNegative;
  const onDecrease = () => {
    if (canDecrease) {
      onChange(value - 1);
    }
  };

  const prefix = value > 0 && showSign ? "+" : "";

  function submitInputForm(e: FormEvent) {
    e.preventDefault();
    if (typeof parsedInput !== "number") {
      return;
    }
    onChange(parsedInput);
    hideInputModal();
  }

  return (
    <>
      <Modal
        isOpen={inputModalVisible}
        className={styles.modal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={hideInputModal}
        style={{
          content: {
            background: "black",
            left: "50%",
            top: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
            // backdropFilter: "blur(2px)",
          },
        }}
      >
        <div className={styles.inputLabel}>{label}</div>
        <form onSubmit={submitInputForm}>
          <input
            autoFocus
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter new value"
            className={styles.valueInput}
            style={invalidInput ? {borderColor: "red"} : {}}
            inputMode="decimal"
          />
        </form>
        <div className={styles.actions}>
          <button onClick={hideInputModal}>Close</button>
          <button type="submit" disabled={typeof parsedInput !== "number"}>
            Save
          </button>
        </div>
      </Modal>
      <div className={styles.container} style={{fontSize: `${rem}rem`}}>
        <button
          style={buttonStyle}
          onClick={onDecrease}
          disabled={!canDecrease}
        >
          -
        </button>
        <button className={styles.count} onClick={showInputModal}>
          {prefix}
          {value}
          {suffix ?? ""}
        </button>
        <button style={buttonStyle} onClick={() => onChange(value + 1)}>
          +
        </button>
      </div>
    </>
  );
}
