import * as t from "runtypes";
import {
  addProduction,
  buildResourceMap,
  ResourceType,
  resourceCounterSchema,
  ResourceCounter,
} from "./types";

const reducerBaseStateSchema = t.Record({
  stockpile: resourceCounterSchema,
  production: resourceCounterSchema,
  generation: t.Number,
  tr: t.Number,
});

export const reducerStateSchema = t.Intersect(
  reducerBaseStateSchema,
  t.Record({
    undoStack: t.Array(reducerBaseStateSchema),
    redoStack: t.Array(reducerBaseStateSchema),
  }),
);

type ReducerBaseState = t.Static<typeof reducerBaseStateSchema>;
export type ReducerState = t.Static<typeof reducerStateSchema>;

const DEFAULT_STATE: ReducerState = {
  stockpile: buildResourceMap(0),
  production: buildResourceMap(1),
  generation: 1,
  undoStack: [],
  redoStack: [],
  tr: 20,
};

type BaseAction<Type extends string, T extends object = {}> = T & {
  type: Type;
};

export type ResetAction = BaseAction<"RESET">;
export type UndoAction = BaseAction<"UNDO">;
export type RedoAction = BaseAction<"REDO">;
export type AdvanceGenerationAction = BaseAction<"ADVANCE_GENERATION">;
export type UpdateStockpileAction = BaseAction<
  "UPDATE_STOCKPILE",
  {resource: ResourceType; newValue: number}
>;
export type UpdateProductionAction = BaseAction<
  "UPDATE_PRODUCTION",
  {resource: ResourceType; newValue: number}
>;
export type UpdateTrAction = BaseAction<"UPDATE_TR", {newValue: number}>;

export type Action =
  | ResetAction
  | UndoAction
  | RedoAction
  | AdvanceGenerationAction
  | UpdateStockpileAction
  | UpdateProductionAction
  | UpdateTrAction;

// TODO: Helpers for the undo/redo boilerplate
export function reducer(
  state: ReducerState = DEFAULT_STATE,
  action: Action,
): ReducerState {
  switch (action.type) {
    case "RESET": {
      return DEFAULT_STATE;
    }
    case "UNDO": {
      const snapshot = state.undoStack[state.undoStack.length - 1];
      if (!snapshot) {
        return state;
      }
      return {
        ...snapshot,
        undoStack: state.undoStack.slice(0, -1),
        redoStack: [...state.redoStack, baseFromState(state)],
      };
    }
    case "REDO": {
      const snapshot = state.redoStack[state.redoStack.length - 1];
      if (!snapshot) {
        return state;
      }
      return {
        ...snapshot,
        redoStack: state.redoStack.slice(0, -1),
        undoStack: [...state.undoStack, baseFromState(state)],
      };
    }
    case "ADVANCE_GENERATION": {
      const start: ResourceCounter = {
        ...state.stockpile,
        energy: 0,
        heat: state.stockpile.heat + state.stockpile.energy,
        money: state.stockpile.money + state.tr,
      };
      return undoable(state, {
        ...state,
        stockpile: addProduction(start, state.production),
        generation: state.generation + 1,
      });
    }
    case "UPDATE_STOCKPILE": {
      return undoable(state, {
        ...state,
        stockpile: {
          ...state.stockpile,
          [action.resource]: action.newValue,
        },
      });
    }
    case "UPDATE_TR": {
      return undoable(state, {
        ...state,
        tr: action.newValue,
      });
    }
    case "UPDATE_PRODUCTION": {
      return undoable(state, {
        ...state,
        production: {
          ...state.production,
          [action.resource]: action.newValue,
        },
      });
    }
    default: {
      return state;
    }
  }
}

function undoable(prev: ReducerState, next: ReducerBaseState): ReducerState {
  return {
    ...next,
    undoStack: [...prev.undoStack, baseFromState(prev)],
    redoStack: [],
  };
}

function baseFromState(state: ReducerState): ReducerBaseState {
  return {
    stockpile: state.stockpile,
    production: state.production,
    generation: state.generation,
    tr: state.tr,
  };
}
